import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./Routes";

import { connect, useSelector } from "react-redux";
import WaitingLoader from "./ReusableComponents/Loader";
import "@stripe/stripe-js";
import { initializeGoogleAnalytics } from "./Utils/googleAnalytycs";

function App(props) {
  const [isLandscapeMobile, setIsLandscapeMobile] = useState(
    (window.innerWidth < 940) & (window.innerWidth > window.innerHeight)
  );

  const { guestId, email, userId, moveInfo } = useSelector(
    (state) => state.User
  );

  window.addEventListener(
    "resize",
    function () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    true
  );

  useEffect(() => {
    if (guestId || userId) {
      const gTag = window.gtag || null;
      // eslint-disable-next-line no-unused-expressions
      gTag?.("config", "GTM-KQ98SR9H", {
        user_id: userId || guestId,
        email: email || "",
        is_guest: userId ? false : true,
        move_info: moveInfo,
      });
    }
  }, [guestId, email, userId, moveInfo]);

  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  const checkOrientation = () => {
    const isMobile = window.innerWidth < 940;
    const isLandscape = window.innerWidth > window.innerHeight;
    setIsLandscapeMobile(isMobile && isLandscape);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener("resize", checkOrientation);
    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  if (isLandscapeMobile)
    return (
      <div className="rotate-screen">
        <img
          className="rotate-screen-icon"
          loading="lazy"
          src={require("./Assets/icons/rotating-phone.svg")}
          alt="Rotate screen icon"
        />
        Rotate your device to portrait mode. <br />
        Horizontal view isn't supported.
      </div>
    );

  return (
    <>
      {props.App.loading && <WaitingLoader />}
      <Routes />
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(App);
