export const colors = {
  blue: {
    100: "#F1FAFE",
    200: "#E2F5FC",
    300: "#BEE8F9",
    400: "#80D8F5",
    500: "#2FC5FF",
    600: "#04ACF0",
    700: "#216FED",
    800: "#1B5ADA",
    900: "#1C49B1",
    1000: "#1D418B",
    1100: "#162955",
  },
  typography: {
    100: "#FFFFFF",
    200: "#F7F9FC",
    300: "#EDF1F7",
    400: "#E4E9F2",
    500: "#C5CEE0",
    600: "#8F9BB3",
    700: "#2E3A59",
    800: "#222B45",
    900: "#192038",
    1000: "#151A30",
    1100: "#101426",
  },
  additionals: {
    "01": "#49A847",
    "02": "#DC3545",
    "03": "#357BDC",
  },
};
