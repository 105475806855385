import React from "react";
import Logo from "../../Assets/images/MoveInOutCompLogo.svg";
import Facebook from "../../Assets/images/Facebook.svg";
import Twitter from "../../Assets/images/Twitter.svg";
import Instagram from "../../Assets/images/Instagram.svg";
import LinkedIn from "../../Assets/images/LinkedIn.svg";

import "./style.css";
const Footer = () => {
  return (
    <footer className="mn-home-footer-wrapper">
      {" "}
      <div className="mn-home-footer">
        <div>
          <img src={Logo} alt="Logo" className="mn-home-footer-Logo" />
          <ul className="mn-home-footer-list d-flex">
            <li>
              <a href="https://www.facebook.com/Moveinout/" target="blank">
                <img
                  src={Facebook}
                  alt="facebook"
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
              </a>
            </li>
            <li>
              <a href="https://mobile.twitter.com/moveinoutuk" target="blank">
                <img
                  src={Twitter}
                  alt="Twitter"
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/moveinout/"
                target="blank"
              >
                <img
                  src={LinkedIn}
                  alt="LinkedIn"
                  style={{ height: "24px", width: "24px", marginRight: "24px" }}
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/moveinoutuk/" target="blank">
                <img
                  src={Instagram}
                  alt="Instagram"
                  style={{ height: "24px", width: "24px" }}
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="mn-home-footer-lists-container">
          <div>
            <ul className="mn-home-footer-list">
              <li className="mn-home-footer-main">Company</li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.moveinout.co.uk/about-us"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  About
                </a>
              </li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.moveinout.co.uk/contact-us"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  Contact us
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="mn-home-footer-list">
              <li className="mn-home-footer-main">Resources</li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.moveinout.co.uk/blog"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  Blog
                </a>
              </li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.moveinout.co.uk/frequently-asked-questions"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  FAQ's
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="mn-home-footer-list">
              <li className="mn-home-footer-main">Legal</li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.iubenda.com/terms-and-conditions/11880077"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  Terms & conditions
                </a>
              </li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.iubenda.com/privacy-policy/11880077/full-legal"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  Privacy policy
                </a>
              </li>
              <li className="mn-home-footer-item">
                <a
                  href="https://www.iubenda.com/privacy-policy/11880077/cookie-policy"
                  target="blank"
                  className="mn-home-footer-link"
                >
                  Cookie notice
                </a>
              </li>
              <li className="mn-home-footer-item">
                <a href="#" target="blank" className="mn-home-footer-link">
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
          <div>
            {/* <ul className="mn-home-footer-list">
            <li className="mn-home-footer-main">Follow us</li>
            <li className="mn-home-footer-item">
              <a className="mn-home-footer-link">
                <i className="fab fa-facebook mn-home-footer-icon"></i>
                Facebook
              </a>
            </li>
            <li className="mn-home-footer-item">
              <a className="mn-home-footer-link">
                <i className="fab fa-twitter mn-home-footer-icon"></i>
                Twitter
              </a>
            </li>
            <li className="mn-home-footer-item">
              <a className="mn-home-footer-link">
                <i className="fab fa-youtube mn-home-footer-icon"></i>
                Youtube
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      <div className="homeFooterEnd">
        <p className="homeFooterPara">
          © Copyright 2025 Moveinout Ltd. All Rights Reserved
        </p>
        <p className="homeFooterPara">
          Moveinout is registered and incorporated in England and Wales.
        </p>
        <p className="homeFooterPara">Company number: 11585502</p>
        <p className="homeFooterPara">ICO number: ZA788144</p>
      </div>
    </footer>
  );
};

export default Footer;
