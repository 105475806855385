import { combineReducers } from "redux";

import AddressReducer from "./Address/reducer";
import MoveDateReducer from "./MoveDate/reducer";
import ServicesReducer from "./Services/reducer";
import SuppliersReducer from "./Suppliers/reducer";
import UserReducer from "./User/reducer";
import AppReducer from "./App/reducer";
import buttonVisibilityReducer from "./ButtonVisibilityReducer/reducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Redux persist configiration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Address", "User", "MoveDate", "Services"],
};

const rootReducer = combineReducers({
  App: AppReducer,
  Address: AddressReducer,
  MoveDate: MoveDateReducer,
  Services: ServicesReducer,
  Suppliers: SuppliersReducer,
  User: UserReducer,
  ButtonVisibilityReducer: buttonVisibilityReducer,
});

export default persistReducer(persistConfig, rootReducer);
