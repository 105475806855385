import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/MoveInOutCompLogo.svg";
import "./style.css";
import Twitter from "../../Assets/images/twitter-col-icon.svg";
import Whatsapp from "../../Assets/images/whatsapp-share.png";
const NewFooter = () => {
  return (
    <footer className="homeFooter">
      <div className="d-flex justify-content-center">
        <img src={Logo} alt="Logo" className="homeFooterLogo" />
      </div>
      <div className="homeFooterList">
        <Link to="/blogs" className="homeFooterListItem">
          Blog
        </Link>
        <Link to="/terms-condition" className="homeFooterListItem">
          Terms & Conditions
        </Link>
        <Link to="/privacy-policy" className="homeFooterListItem">
          Privacy Policy
        </Link>
        <Link to="/cookie-policy" className="homeFooterListItem">
          Cookie Notice
        </Link>
        <Link to="/faq" className="homeFooterListItem">
          FAQ
        </Link>
        <Link to="/contact-us" className="homeFooterListItem">
          Contact Us
        </Link>
      </div>
      <div className="homeFooterMedias">
        <a
          href="https://www.facebook.com/moveinout/"
          target="blank"
          className="homeFooterIconLink"
        >
          <img
            src={require("../../Assets/images/facebook-col-icon.svg")}
            target="blank"
            alt="FB"
            className="homeFooterIcon"
          />
        </a>
        <a
          href="https://twitter.com/moveinoutuk"
          target="blank"
          className="homeFooterIconLink"
        >
          <img src={Twitter} alt="TwitterIcon" className="homeFooterIcon" />
        </a>
        <a
          href="whatsapp://send?text=http://moveinout.co.uk"
          target="blank"
          className="homeFooterIconLink "
          style={{ display: "inline-block !important" }}
        >
          <img src={Whatsapp} alt="whatsappIcon" className="homeFooterIcon " />
        </a>
      </div>
      <div className="homeFooterEnd">
        <p className="homeFooterPara">
          © Copyright 2025 Moveinout Ltd. All Rights Reserved
        </p>
        <p className="homeFooterPara">
          Moveinout is registered and incorporated in England and Wales.
        </p>
        <p className="homeFooterPara">Company number 11585502</p>
        <p className="homeFooterPara">
          Data Protection Registration Number: ZA788144
        </p>
      </div>
    </footer>
  );
};

export default NewFooter;
