import React from "react";
const LazyLoader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "100dvh",
        width: "100vw",
        backgroundColor: "#fff",
      }}
    >
      <img src={require("../../Assets/images/MoveInOut-Logo.svg")} alt="icon" />
    </div>
  );
};

export default LazyLoader;
