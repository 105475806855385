export const typography = {
  h1: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "68px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "40px",
      fontWeight: "400",
      lineHeight: "68px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "40px",
      fontWeight: "300",
      lineHeight: "68px",
    },
  },
  h2: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "40px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "32px",
      fontWeight: "400",
      lineHeight: "40px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "32px",
      fontWeight: "300",
      lineHeight: "40px",
    },
  },
  h3: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "32px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "24px",
      fontWeight: "300",
      lineHeight: "32px",
    },
  },
  h4: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "20px",
      fontWeight: "300",
      lineHeight: "24px",
    },
  },
  body: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "300",
      lineHeight: "24px",
    },
  },
  body2: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "20px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "20px",
    },
  },
  body3: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "16px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "16px",
    },
  },
  body4: {
    700: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "16px",
    },
    400: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
    },
    300: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "16px",
    },
  },
  label: {
    fontFamily: "Helvetica",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
    textTransform: "uppercase",
  },
};
