import React, { useState, useEffect } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";

import ReallymovingLogo from "../../Assets/icons/Reallymoving.png";

const ReallyMovingLayout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 844);
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 844);
  };

  useEffect(() => {
    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  if (process.env.REACT_APP_MODE !== "prod-reallymoving")
    return <div className="container-styles">{children}</div>;

  return (
    <div className="container-styles">
      {!isLoginPage && (
        <header className="menu-header">
          <div className="menu-inner">
            <div className="left">
              <a
                href="https://www.reallymoving.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={
                    isMobile ? "chapperone-logo-mobile" : "reallymoving-logo"
                  }
                  src="https://media.umbraco.io/reallymoving2/mwpcygsj/rm-logo-25yrs-uncropped.svg"
                  alt="ReallyMoving"
                />
              </a>

              {isMobile && (
                <a
                  href="https://app.moveinout.co.uk/getStarted"
                  className="chapperone-logo-wrapper"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="st-navbar-icon chapperone-logo-mobile"
                    src={require("../../Assets/images/Moveinout-logo.png")}
                    alt="MoveInOut"
                  />
                </a>
              )}
            </div>
            <div className="right">
              {!isMobile && (
                <>
                  <nav className="menu-nav">
                    <ul className="menu-list">
                      <li>
                        <a
                          href="https://www.reallymoving.com/about-us"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          About us
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.reallymoving.com/contact-us"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contact us
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.reallymoving.com/become-a-partner"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Join us
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://admin.reallymoving.com/log-in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Partner login
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <button
                    className="menu-button"
                    onClick={() =>
                      window.open(
                        "https://www.reallymoving.com/retrieve-your-quotes",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    Retrieve quotes
                  </button>
                </>
              )}
            </div>
          </div>
        </header>
      )}

      {children}
    </div>
  );
};

export default ReallyMovingLayout;
